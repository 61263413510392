



























































import Vue from 'vue';
import Component from 'vue-class-component';
import {createHelpers} from 'vuex-map-fields';
import {ILtiKey} from '@/services/api/lti.service';

import LtiKeysConfirmModal from '@/pages/Lti/LtiKeysConfirm.modal.vue';
import InputLink from '@/components/UI/InputLink.vue';

import DeleteIcon from '@/assets/icon/delete.svg';
import PlusIcon from '@/assets/icon/plus.svg';
import CopyToClipBoard from '@/components/UI/CopyToClipBoard.vue';

const { mapFields } = createHelpers({
  getterType: 'getLtiField',
  mutationType: 'updateLtiField',
});

@Component({
  components: {
    DeleteIcon,
    PlusIcon,
    LtiKeysConfirmModal,
    InputLink,
    CopyToClipBoard
  },
  computed: {...mapFields([
      'keyList'
    ]),
  }
})

export default class LtiKeys extends Vue {
  private DeleteIcon = DeleteIcon;
  private PlusIcon = PlusIcon;

  private keyList!: ILtiKey[];

  private connectionUrl: string = `${window.location.origin}/api/lti`;
  private keyName: string = 'LTI-Zugang';

  public created() {
    this.$store.dispatch('getLtiKeys');
  }

  public get isKeyNameValid(): boolean {
    return this.keyName.length > 1
      && this.keyName.length < 21;
  }

  public get isKeyNameDublicated(): boolean {
    return !!this.keyList.filter(item => item.keyName === this.keyName)[0];
  }

  public addKey() {
    if (!this.isKeyNameValid) return;
    if (this.keyList.length && this.isKeyNameDublicated) {
      this.$notify({
          group: 'app',
          type: 'warning',
          title: 'LTI-Schlüssel',
          text: 'Schlüsselname veröffentlicht'
      });
      return;
    }
    this.keyList.length < 5 
      ? this.$store.dispatch('addLtiKey', this.keyName)
        .then(() => this.keyName = 'LTI-Zugang')
      : this.$notify({
          group: 'app',
          type: 'warning',
          title: 'LTI-Schlüssel',
          text: 'Maximale Anzahl der erreichten Schlüssel'
        });
  }

  public removeKey(id: string) {
    (this.$refs.modal as LtiKeysConfirmModal).openModal(id);
  }

  public onCopySuccess() {
    this.$notify({
      group: 'app',
      type: 'success',
      title: 'Schlüssel kopiert',
      text: 'Dieser Schlüssel steht für Sie zum Einfügen bereit.'
    });
  }
}
