





















import { Vue, Component } from 'vue-property-decorator';
import adaptiveButtonSize from '@/mixins/adaptiveButtonSize';

@Component({
  mixins: [adaptiveButtonSize]
})

export default class LtiKeysConfirmModal extends Vue {
  private visible: boolean = false;
  private id: string | null = null;

  public removeKey() {
    this.$store.dispatch('removeLtiKey', this.id)
      .then(() => this.closeModal())
      .then(() => this.$notify({
        group: 'app',
        type: 'success',
        title: 'Schlüssel gelöscht',
        text: 'Der Schlüssel wurde gelöscht.'
      }));
  }

  public openModal(id: string): void {
    this.id = id;
    this.visible = true;
  }

  public closeModal(): void {
    this.visible = false;
  }
}
